<template>
    <div v-if="isShow" class="modal" :class="[`modal--${$options.name}`]">
        <div class="modal__close btn btn--remove" @click="close"></div>

        <div class="modal__title">
            <template v-if="$root.createdOrder.payload.type === 'takeaway'">
                <template v-if="$root.createdOrder.payload.time.type === 'soon'">
                    <template v-if="$root.createdOrder.finishTime > 90">
                        Ваш заказ #{{ $root.createdOrder.orderId }}, с вами свяжется оператор для подтверждения заказа
                    </template>
                    <template v-else>
                        Самовывоз #{{ $root.createdOrder.orderId }}
                        на сумму {{ $root.createdOrder.payload.payment.total }} руб.
                        будет готов {{ formatDate($root.createdOrder.payload.time.value.date) }}
                        до {{ timeFromFinishTime }}
                        по адресу г.{{ $root.city.name }}, {{ $root.shops.find(shop => shop.id === $root.createdOrder.payload.shop.id).address }}
                    </template>
                </template>

                <template v-else>
                    Самовывоз #{{ $root.createdOrder.orderId }}
                    на сумму {{ $root.createdOrder.payload.payment.total }} руб.
                    будет готов {{ formatDate($root.createdOrder.payload.time.value.date) }} К {{ $root.createdOrder.payload.time.value.time }}
                    по адресу г.{{ $root.city.name }}, {{ $root.shops.find(shop => shop.id === $root.createdOrder.payload.shop.id).address }}
                </template>
            </template>

            <template v-else>
                <template v-if="$root.createdOrder.payload.time.type === 'soon'">
                    <template v-if="$root.createdOrder.finishTime > 120">
                        Ваш заказ №{{ $root.createdOrder.orderId }}, с вами свяжется оператор для подтверждения заказа
                    </template>
                    <template v-else>
                        Заказ #{{ $root.createdOrder.orderId }}
                        на сумму {{ $root.createdOrder.payload.payment.total }}
                        руб. будет доставлен {{ formatDate($root.createdOrder.payload.time.value.date) }}
                        до {{ timeFromFinishTime }}.
                        По адресу г. {{ $root.city.name }}, {{ $root.createdOrder.payload.delivery.formated }}
                    </template>
                </template>

                <template v-else>
                    Заказ #{{ $root.createdOrder.orderId }}
                    на сумму {{ $root.createdOrder.payload.payment.total }} руб.
                    будет доставлен {{ formatDate($root.createdOrder.payload.time.value.date) }}
                    К {{ $root.createdOrder.payload.time.value.time }}
                    по адресу г.{{ $root.city.name }}, {{ $root.createdOrder.payload.delivery.formated }}
                </template>
            </template>
        </div>

        <div class="btn btn--red" @click="close">Хорошо</div>
    </div>
</template>

<script>
export default {
    name: 'order-created',

    methods: {
        open() {
            this.$root.openModal(this.$options.name);
        },

        close() {
            window.location.pathname = '/';
        },

        formatDate(dateString) {
            return window.moment(dateString).format('DD.MM.YYYY')
        },
    },

    computed: {
        isShow() {
            return this.$root.modal.open.name === this.$options.name;
        },

        orderMomentTime() {
            return window.moment(`${this.$root.createdOrder.payload.time.value.date} ${this.$root.createdOrder.payload.time.value.time}`).calendar().toLowerCase();
        },

        payloadShop() {
            return this.$root.shops.find((shop) => shop.id === this.$root.createdOrder.payload.shop.id);
        },

        timeFromFinishTime() {
            return moment().add(this.$root.createdOrder.finishTime, "minutes").format('HH:mm')
        }
    },

    watch: {
        isShow: function (isShow) {
            if (isShow === false) this.close();
        }
    }
}
</script>
