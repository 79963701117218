<template>
    <slider ref="slider" id="product-slider" class="product-slider" :settings="hooperSettings" style="width: 100%">
        <slide class="product-slide" v-for="(product, index) in products" :key="product.id" :index="index">
            <div class="product-slide-inner">
                <img class="product-slide__image" :src="product.img.big" :alt="product.name" ondragstart="return false">

                <div class="product-slide__info-wrapper">
                    <div class="product-slide__title">{{ product.name }}</div>
                    <product-counter class="mt-2" :product="product" :transparent="false" :buttonIfRecommendation="true" />
                </div>
            </div>
        </slide>

        <navigation slot="hooper-addons">
            <div slot="hooper-next">
                <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2542 22L20 15.496L13.2542 9" stroke="#8A8A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div slot="hooper-prev">
                <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.7458 22L12 15.496L18.7458 9" stroke="#8A8A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </navigation>
    </slider>
</template>

<script>
import { Hooper as Slider, Slide, Navigation } from 'hooper';

export default {
    name: 'ProductSlider',
    props: [ 'products' ],
    components: { Slider, Slide, Navigation },

    data() {
        return {
            hooperSettings: {
                infiniteScroll: true,
                wheelControl: false,
                breakpoints: {
                    1440: {
                        itemsToShow: 3.6
                    },
                    600: {
                        itemsToShow: 3
                    },
                    520: {
                        itemsToShow: 3
                    },
                    0: {
                        itemsToShow: 2.3
                    }
                }
            }
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.slider.$el.style.width = '';

            if (window.innerWidth < 1024) this.$refs.slider.updateWidth();
        });
    }
}
</script>

<style lang="scss">
    @import '../../../sass/components/product-slider.scss';
</style>
