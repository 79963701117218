<template>
    <div class="payment_method__form">
        <div class="payment_method__title">
            Способ оплаты
        </div>
        <div class="block-group" style="margin: 0">
           <type-payment v-if="isNotShowMobile" />
        </div>
        <promocode v-if="isNotShowMobile" />
        <div class="payment_method__order-total">
            <div class="payment_method__order-delivery" v-show="isDelivery">Доставка - {{ $root.deliveryInfo.price === undefined ? '(адрес не задан)' : $root.deliveryInfo.price + ' ₽' }}</div>
            <div class="payment_method__order-delivery" v-show="!isDelivery">Доставка - бесплатно</div>
            <div class="payment_method__order-amount">
                <p>Итого:</p>
                <span>{{ $root.basket.cart.price.total - ($root.uds.applied ? $root.uds.receipt.points : 0) }} ₽</span>
            </div>
        </div>
        <button class="payment_method__order-create"
                @click="$emit('createOrder')">
            {{ $root.payment.slug === 'cash' || $root.payment.slug === 'card' ? 'Оформить заказ' : 'Оплатить' }}
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            code: 'uds',
            useUds: false,
            payment: {},
        }
    },
    computed: {
        isDelivery() {
            return this.$root.orderType === 'delivery';
        },
        isNotShowMobile() {
            return this.$root.window.width > 550;
        }
    },
    watch: {
        payment(payment) {
            this.$emit('getPayment', payment);
        }
    },
    methods: {
        applyPromocode(promocode) {
            this.$emit('applyPromocode', promocode);
        },
        applyUds(uds) {
            this.$emit('applyUds', uds);
        },
        clearPromocode() {
            this.$emit('clearPromocode');
        },
        clearUds() {
            this.$emit('clearUds');
        }
    }
}
</script>

<style scoped>

</style>
