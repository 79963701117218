import deliveryAddressMixin from './deliveryAddress.js'; // Адрес доставки
import timesMixin from './times.js'; // Вычесление времени относительно пользователя
import cookieMixin from './cookie.js'; // Вычесление времени относительно пользователя
import modalsMixin from './modals.js'; // Методы модалок
import cacheMixin from './cache.js'; // Получение и запись кеша
import statsMetrics from './statsMetrics.js'; // Получение и запись кеша
import orderType from './orderType.js'; // Логика при изменении типа заказа (доставка, самовывоз)
import { getStopList } from "../../axios/axios_stop-list";

export default {
    mixins: [
        deliveryAddressMixin,
        timesMixin,
        cookieMixin,
        modalsMixin,
        cacheMixin,
        statsMetrics,
        orderType
    ],
    data: {
        ...window.initialState,
        utm_data: {},
        shop: {
            id: null, //Выбранная торговая точка
            name: '',
            address: '',
            payment_type: []
        },
        phone: null,
        typeCode: 'uds',
        window: {
            width: 0,
            height: 0
        },
        promocode: {
            key: '',
            error: null
        },
        uds: {
            key: '',
            applied: false,
            needCertificate: false,
            errors: [],
            customer: {
                cashbackRate: null,
                points: null,
                membershipTier: {
                    maxScoresDiscount: null,
                    name: null,
                    uid: null,
                },
            },

            receipt: {
                total: 0,
                points: 0,
            },

            purchase: {
                cash: 0,
                total: 0,
                points: 0,
                certificatePoints: 0,
                maxPoints: 0,
                cashBack: 0,
                pointsPercent: 0,
            }
        },
        payment: {
            cash: 0,
            id: null,
            is_online: false,
            name: "",
            slug: "",
            sort: null,
        },
        cookingTimeDelivery: null,
        isChangeTotal: false,
        stopList: null,
        loadingUds: false
    },
    methods: {
        getStopList,
        //Функция вывода расписания
        scheduleShow(name, schedule, type) {
            let workDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
            let data = '';
            data += `<span class="container-time">${name}`;
            let showWeek = (schedule.length !== 1);
            schedule.forEach((item) => {
                let start = (type === 'takingOrders') ? item.takingOrdersStart : item.openTime;
                let end = (type === 'takingOrders') ? item.takingOrdersEnd : item.closeTime;
                data += `<div class="container-time__schedule">`;
                if (showWeek) {
                    data += `<span>${workDays[item.startDay - 1]}`;
                    if (item.startDay !== item.endDay) data += ` - ${workDays[item.endDay - 1]}`;
                    data += ` : </span>`;

                }
                data += ` <span>${start.substr(0, 5)} — ${end.substr(0, 5)}</span>`;
                data += `</div>`;
            });
            data += `</span>`;

            return data;
        },
        closeBurgerMenu() {
            this.$nextTick(() => {
                let siteHeaderWrapper = document.querySelector('.site-header__wrapper');
                let siteHeader = document.querySelector('.site-header');
                let siteMenuMobile = document.querySelector('.site-menu--mobile');
                let button = document.querySelector('.site-header .icon--burger-button');
                let siteHeaderAfter = document.querySelector('.site-header__after');

                siteHeaderWrapper.removeAttribute('mobile-menu-show');
                siteHeader.removeAttribute('mobile-menu-show');
                siteMenuMobile.removeAttribute('show');
                siteHeaderAfter.removeAttribute('mobile-menu-show-after');
                button.classList.remove('icon--cross');
            });
        },
        parseUtmSource() {
            if (document.location.search) {
                let pair = (document.location.search.substr(1)).split('&')
                for (let i = 0; i < pair.length; i ++) {
                    let param = pair[i].split('=')
                    this.utm_data[param[0]] = param[1]
                }
            }
            if (!!this.utm_data.utm_source) {
                this.setCookie('utm_source', 1, {'max-age': 3600 * 24 * 3})
            }
        },
        replaceNumber() {
            if (this.city.callCenterPhoneParameters) {
                this.company_phone.marks = this.city.callCenterPhoneParameters.number ?? this.company_phone.marks
                this.company_phone.with_out = this.city.callCenterPhoneParameters.numberWithoutMarks ?? this.company_phone.with_out
            }

            let mobile = $('.footer__7XEcO.footer__3Ye4c a')
            if (mobile) {
                mobile.attr('href', 'tel:'+ this.company_phone.with_out).text(this.company_phone.marks)
            }

            let dekstops = $('a.js-feedbackTel')
            if (dekstops) {
                dekstops.attr('data-href', 'tel:'+ this.company_phone.with_out).attr('href', 'tel:'+ this.company_phone.with_out)
                dekstops.find('span[itemprop="telephone"]').text(this.company_phone.marks)
            }
        },
        auto_layout_keyboard(str) {
            var replacer = {
                "q":"й", "w":"ц", "e":"у", "r":"к", "t":"е", "y":"н", "u":"г",
                "i":"ш", "o":"щ", "p":"з", "[":"х", "]":"ъ", "a":"ф", "s":"ы",
                "d":"в", "f":"а", "g":"п", "h":"р", "j":"о", "k":"л", "l":"д",
                ";":"ж", "'":"э", "z":"я", "x":"ч", "c":"с", "v":"м", "b":"и",
                "n":"т", "m":"ь", ",":"б", ".":"ю", "/":"."
            };

            return str.replace(/[A-z/,.;\'\]\[]/g, function ( x ) {
                return x == x.toLowerCase()
                    ? replacer[ x ]
                    : replacer[ x.toLowerCase() ].toUpperCase();
            });
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    created() {
        this.setInitialOrderType()
        this.replaceNumber()
        this.parseUtmSource()
        window.localStorage.setItem('cartHash', this.basket.cart.hash)
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        if (['/', '/basket'].includes(location.pathname)) {
            this.getStopList().then(({data}) => this.stopList = data.result);
        }
    },
    computed: {
        cacheExpireDate() {
            return (24 * 60 * 60 * 1000);
        },
        isEnableApplePay() {
            return window.ApplePaySession ? true : false;
        },
        cartProductsSorted() {
            return Object.keys(this.basket.cart.goods).map((key) => this.basket.cart.goods[key])
                .sort((a, b) => {
                    if (a.timestamp > b.timestamp) return 1;
                    if (a.timestamp < b.timestamp) return -1;
                    return 0;
                });
        },
        isMainPage() {
            return  window.location.pathname === '/';
        }
    }
}
